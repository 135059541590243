@import "~@angular/material/theming";

$default-font-family: Roboto, "Helvetica Neue", sans-serif !important;

$custom-typography: mat-typography-config(
  $font-family: "inherit",
);
@include mat-base-typography($custom-typography);
@include mat-checkbox-typography($custom-typography);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);

$background-color: #f1f4f6;

$md-opt1-secondary: (
  50: #e8f6f6,
  100: #c5e9e9,
  200: #9edadb,
  300: #77cbcd,
  400: #5abfc2,
  500: #3db4b7,
  600: #37adb0,
  700: #2fa4a7,
  800: #279c9f,
  900: #1a8c90,
  A100: #c8fdff,
  A200: #95fbff,
  A400: #62faff,
  A700: #48f9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-opt1-primary: (
  50: #e4e3ea,
  100: #bcbaca,
  200: #8f8ca7,
  300: #625d84,
  400: #403b69,
  500: #1e184f,
  600: #1a1548,
  700: #16113f,
  800: #120e36,
  900: #0a0826,
  A100: #6a63ff,
  A200: #3930ff,
  A400: #0b00fc,
  A700: #0a00e2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// verve
$verv-primary: mat-palette($md-opt1-primary);
$verv-accent: mat-palette($md-opt1-secondary);
$verv-warn: mat-palette($mat-red);
$verv-theme: mat-light-theme(
  (
    color: (
      primary: $verv-primary,
      accent: $verv-accent,
      warn: $verv-warn,
    ),
  )
);
.verv-theme {
  @include angular-material-theme($verv-theme);
}

//cirt
$cirt-primary: mat-palette($md-opt1-primary);
$cirt-accent: mat-palette($md-opt1-secondary);
$cirt-warn: mat-palette($mat-red);
$cirt-theme: mat-light-theme(
  (
    color: (
      primary: $cirt-primary,
      accent: $cirt-accent,
      warn: $cirt-warn,
    ),
  )
);
.cirt-theme {
  @include angular-material-theme($cirt-theme);
}
.cirt-theme {
  @include angular-material-theme($verv-theme);
  // @include mat-base-typography($verv-typography);
  // @include angular-material-typography($verv-typography);
  // @include mat-core($verv-typography);
}


//SMRT
$smrt-primary: mat-palette($md-opt1-primary);
$smrt-accent: mat-palette($md-opt1-secondary);
$smrt-warn: mat-palette($mat-red);
$smrt-theme: mat-light-theme(
  (
    color: (
      primary: $smrt-primary,
      accent: $smrt-accent,
      warn: $smrt-warn,
    ),
  )
);
.smrt-theme {
  @include angular-material-theme($smrt-theme);
}
.smrt-theme {
  @include angular-material-theme($verv-theme);
  // @include mat-base-typography($verv-typography);
  // @include angular-material-typography($verv-typography);
  // @include mat-core($verv-typography);
}
